<template>
  <svg v-if="chain == 'eth'" viewBox="0 0 784.37 1277.39">
    <g fill-rule="nonzero">
      <path
        opacity="0.8"
        d="m392.07 0-8.57 29.11v844.63l8.57 8.55 392.06-231.75z"
      />
      <path opacity="0.6" d="M392.07 0 0 650.54l392.07 231.75V472.33z" />
      <path
        opacity="0.8"
        d="m392.07 956.52-4.83 5.89v300.87l4.83 14.1 392.3-552.49z"
      />
      <path opacity="0.6" d="M392.07 1277.38V956.52L0 724.89z" />
      <path opacity="1" d="m392.07 882.29 392.06-231.75-392.06-178.21z" />
      <path opacity="0.8" d="m0 650.54 392.07 231.75V472.33z" />
    </g>
  </svg>
  <svg
    v-else-if="chain == 'ftm'"
    viewBox="0 0 12 20"
    xml:space="preserve"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path
      d="m7.2 6.9 3.6-2.1V9L7.2 6.9Zm3.6 9L6 18.7l-4.8-2.8V11L6 13.8l4.8-2.8v4.9ZM1.2 4.8l3.6 2.1L1.2 9V4.8Zm5.4 3.1 3.6 2.1-3.6 2.1V7.9Zm-1.2 4.2L1.8 10l3.6-2.1v4.2Zm4.8-8.3L6 6.2 1.8 3.8 6 1.3l4.2 2.5ZM0 3.4v13.1l6 3.4 6-3.4V3.4L6 0 0 3.4Z"
    />
  </svg>
  <svg
    v-else-if="chain == 'avax'"
    viewBox="0 0 953 1165"
    xml:space="preserve"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path
      d="M80.8529682 995.1239458c-33.59764078 0-50.19351062 0-60.31251534-6.472477C9.61074297 981.564655 2.93202433 969.826434 2.12241619 956.8705097c-.60775462-11.9356864 7.69127733-26.51302104 24.28714717-55.65781705l394.7881861-695.8680696c16.79882039-29.54960008 25.29860883-44.32440012 36.02536817-49.78870655 11.53636748-5.8691105 25.29970586-5.8691105 36.83607334 0 10.72675934 5.46430643 19.22764481 20.23910647 36.02536817 49.78870655l81.16047346 141.67484232.41358031.72294277c18.14377917 31.70087591 27.34456978 47.77675353 31.36189364 64.64907493 4.45174774 18.41803667 4.45174774 37.847535 0 56.26557167-4.0480407 17.00067391-13.15558376 33.19283671-31.57362043 65.37311473L404.07420819 900.60603506l-.53644767.93905768c-18.26335544 31.96196905-27.51899755 48.16181106-40.34656934 60.38272526-13.9651919 13.3618254-30.76401229 23.0705409-49.18204896 28.5447206-15.74896268 4.3661794-33.27621099 4.6294666-66.42406947 4.6514072H80.8529682Zm563.6649843 0H876.7372629c31.7370779-.0109703 48.0060328-.2084357 57.813481-6.6699424 10.9264188-7.0868138 17.8047969-19.0334705 18.4191337-31.9784245.5814259-11.5517259-7.5365961-25.560799-23.4435311-53.01178069-.548515-.93466956-1.09703-1.88579457-1.6565153-2.85337503L813.1095229 704.2903225l-1.3054657-2.21051545c-16.126341-27.26997174-24.2663036-41.04098933-34.7209995-46.36377889-11.5297853-5.87020753-25.1000464-5.87020753-36.63092873 0-10.52490582 5.46430643-19.02469426 19.8343024-35.82351465 48.77614786L590.27640118 900.81337373l-.39163971.67577048c-16.73958077 28.8957702-25.10553155 43.33597609-24.50216505 55.18390009.80960814 12.9559243 7.48832678 24.8916107 18.41693964 31.9784245 9.91824823 6.472477 26.91892214 6.472477 60.71841644 6.472477Z"
    />
  </svg>
  <svg
    v-else-if="chain == 'cro'"
    viewBox="0 0 260 300"
    xml:space="preserve"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path
      d="m129.9038 0 129.904 75v150l-129.904 75-129.904-75V75l129.904-75Zm0 46 90.067 52v104l-90.067 52-90.067-52V98l90.067-52Zm60.146 68.725-25.034 14.547L129.9038 109l-35.507 20.5v41l35.507 20.5 35.112-20.272 25.034 14.547L129.9038 220l-60.622-35v-70l60.622-35 60.146 34.725Z"
    />
  </svg>
  <svg
    v-else-if="chain == 'arbi'"
    viewBox="0 0 433 486"
    xml:space="preserve"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path
      d="m402.406 311.21925-95.836-149.268-35.654 60.5 92.774 150.043-17.308 10.031-87.952-138.627-40.88 69.371 66.968 105.108-62.737 36.357c-3.379 1.221-7.33 1.152-10.681-.21l-74.227-42.692 159.111-269.885-70.51.256-159.522 263.242 55.757 32.103 66.706 38.406c2.206 1.255 4.171 2.367 5.784 3.272 2.497 1.4 4.199 2.337 4.8 2.629 4.741 2.303 11.563 3.643 17.71 3.643 5.636 0 11.132-1.035 16.332-3.072l182.225-105.531c10.459-8.104 16.612-20.325 17.166-33.564v-201.747c-.59-14.746-8.574-28.245-21.08-36.104l-175.29-100.805c-12.371-6.229-27.825-6.237-40.218-.004-1.465.739-170.465 98.752-170.465 98.752-2.339 1.122-4.592 2.458-6.711 3.975-11.164 8.001-17.969 20.435-18.668 34.095v208.765l30.115-46.271-.263-161.083c.035-.589.109-1.169.214-1.741.681-3.79 2.797-7.171 5.983-9.456.795-.569 172.682-100.064 173.228-100.337 4.04-2.029 9.175-2.053 13.22-.063l173.022 99.523c4.093 2.607 6.659 7.027 6.887 11.859v168.53ZM.001 367.45225l47.369 27.296 157.634-252.801-26.753-.706c-22.795-.322-47.35 5.604-58.62 24.001l-89.515 138.745-30.115 46.271v17.194Z"
      fill="#fff"
      fill-rule="nonzero"
    />
  </svg>
  <svg
    v-else-if="chain == 'base'"
    viewBox="0 0 2500 2500"
    xml:space="preserve"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path
      d="M1247.8,2500c691.6,0,1252.2-559.6,1252.2-1250C2500,559.6,1939.4,0,1247.8,0C591.7,0,53.5,503.8,0,1144.9h1655.1v210.2H0   C53.5,1996.2,591.7,2500,1247.8,2500z"
      fill="#fff"
      fill-rule="nonzero"
    />
  </svg>
  <svg v-else-if="chain == 'poly'" viewBox="0 0 38.4 33.5" xml:space="preserve">
    <path
      d="M29 10.2c-.7-.4-1.6-.4-2.4 0L21 13.5l-3.8 2.1-5.5 3.3c-.7.4-1.6.4-2.4 0L5 16.3c-.7-.4-1.2-1.2-1.2-2.1v-5c0-.8.4-1.6 1.2-2.1l4.3-2.5c.7-.4 1.6-.4 2.4 0L16 7.2c.7.4 1.2 1.2 1.2 2.1v3.3l3.8-2.2V7c0-.8-.4-1.6-1.2-2.1l-8-4.7c-.7-.4-1.6-.4-2.4 0L1.2 5C.4 5.4 0 6.2 0 7v9.4c0 .8.4 1.6 1.2 2.1l8.1 4.7c.7.4 1.6.4 2.4 0l5.5-3.2 3.8-2.2 5.5-3.2c.7-.4 1.6-.4 2.4 0l4.3 2.5c.7.4 1.2 1.2 1.2 2.1v5c0 .8-.4 1.6-1.2 2.1L29 28.8c-.7.4-1.6.4-2.4 0l-4.3-2.5c-.7-.4-1.2-1.2-1.2-2.1V21l-3.8 2.2v3.3c0 .8.4 1.6 1.2 2.1l8.1 4.7c.7.4 1.6.4 2.4 0l8.1-4.7c.7-.4 1.2-1.2 1.2-2.1V17c0-.8-.4-1.6-1.2-2.1L29 10.2z"
    />
  </svg>
  <svg
    v-else-if="chain == 'sonic'"
    viewBox="0 0 198 199"
    xml:space="preserve"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(1,0,0,1,-101.036,-100.792)">
      <path
        d="M101.036,193C102.399,173.483 109.418,155.514 120.463,140.724C153.688,167.525 189.777,183.491 223.784,193L101.036,193ZM129.61,130.118C134.979,124.71 140.966,119.916 147.456,115.853C165.5,142.504 192.67,163.688 225.098,178.9C193.544,169.791 160.298,154.797 129.61,130.118ZM159.838,109.271C172.119,103.821 185.708,100.792 200,100.792C250.537,100.792 292.298,138.661 298.432,187.546C239.853,176.797 188.049,150.295 159.838,109.271ZM298.432,212.454C292.298,261.339 250.537,299.208 200,299.208C185.708,299.208 172.119,296.179 159.838,290.729C188.049,249.705 239.853,223.203 298.432,212.454ZM147.456,284.147C140.966,280.084 134.979,275.29 129.61,269.882C160.298,245.203 193.544,230.209 225.098,221.1C192.67,236.312 165.5,257.496 147.456,284.147ZM120.463,259.276C109.418,244.486 102.399,226.517 101.036,207L223.784,207C189.777,216.509 153.688,232.475 120.463,259.276Z"
      />
    </g>
  </svg>
  <svg
    v-else-if="chain == 'sol'"
    viewBox="0 0 398 312"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(0.880562,0,0,0.880562,23.7503,18.6144)">
      <path
        d="M64.6,237.9C67,235.5 70.3,234.1 73.8,234.1L391.2,234.1C397,234.1 399.9,241.1 395.8,245.2L333.1,307.9C330.7,310.3 327.4,311.7 323.9,311.7L6.5,311.7C0.7,311.7 -2.2,304.7 1.9,300.6L64.6,237.9Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(0.880562,0,0,0.880562,23.7503,18.6144)">
      <path
        d="M64.6,3.8C67.1,1.4 70.4,0 73.8,0L391.2,0C397,0 399.9,7 395.8,11.1L333.1,73.8C330.7,76.2 327.4,77.6 323.9,77.6L6.5,77.6C0.7,77.6 -2.2,70.6 1.9,66.5L64.6,3.8Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
    <g transform="matrix(0.880562,0,0,0.880562,23.7503,18.6144)">
      <path
        d="M333.1,120.1C330.7,117.7 327.4,116.3 323.9,116.3L6.5,116.3C0.7,116.3 -2.2,123.3 1.9,127.4L64.6,190.1C67,192.5 70.3,193.9 73.8,193.9L391.2,193.9C397,193.9 399.9,186.9 395.8,182.8L333.1,120.1Z"
        style="fill: white; fill-rule: nonzero"
      />
    </g>
  </svg>
  <svg v-else viewBox="0 0 126.61 126.61">
    <g>
      <path
        d="m38.73 53.2 24.59-24.58 24.6 24.6 14.3-14.31L63.32 0l-38.9 38.9zM0 63.31 14.3 49l14.31 14.31-14.31 14.3zM38.73 73.41 63.32 98l24.6-24.6 14.31 14.29-38.9 38.91-38.91-38.88zM98 63.31 112.3 49l14.31 14.3-14.31 14.32z"
      />
      <path
        d="M77.83 63.3 63.32 48.78 52.59 59.51l-1.24 1.23-2.54 2.54 14.51 14.5 14.51-14.47z"
      />
    </g>
  </svg>
</template>

<script>
export default { name: "ChainLogo", props: { chain: String } };
</script>
